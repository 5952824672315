<template>
  <div class="landing-view">
    <video ref="player" autoplay muted loop>
      <source type="video/mp4" src="@/assets/videos/sample.mp4" />
    </video>
    <div class="land-overlay">
      <CustomNav />
      <div class="container">
        <div class="row">
          <div class="col-md-7">
            <div class="landing send-errand-caption">
              <h1>Relax and Get Served.</h1>
              <p>
                Discover the fastest and easiest way to complete errands.
              </p>
              <p class="caption-p">Your business, our business.</p>
              <div class="btn-set">
                <button class="btn-app play-store" @click="openApp('android')">
                  <span class="-icon"><i class="play-store-icon"></i></span>
                </button>
                <button class="btn-app app-store" @click="openApp('ios')">
                  <span class="-icon"><i class="app-store-icon"></i></span>
                </button>
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <div class="video-panel">
              <div class="wrap-option">
                <!-- 
                    @change="seek"
                    @input="seek"
                  -->
                <input
                  type="range"
                  min="0"
                  :max="player.duration"
                  :value="currentTime"
                  :class="$style.progress"
                />
                <button class="btn-play" @click="toggleVideo">
                  <span class="inner-btn">
                    <font-awesome-icon :icon="videoPlay ? faPause : faPlay" />
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
:root {
  --videoProgress: 1%;
}
</style>
<style lang="scss" module>
@import url("https://fonts.googleapis.com/css2?family=Niramit:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,200;1,300;1,400;1,500;1,600;1,700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

.progress {
  // height: 5px;
  appearance: none;
  margin: 18px 0;
  width: 100%;
  border: none;
  -webkit-appearance: none;
  &:focus {
    outline: none;
    transition: 0.4s ease-in-out;
    &::-ms-fill-lower {
      background: linear-gradient(
        to right,
        $primary-color 0%,
        $primary-color var(--videoProgress),
        rgba(0, 0, 0, 0) var(--videoProgress),
        rgba(0, 0, 0, 0) 100%
      );
    }
    &::-ms-fill-upper {
      background-color: transparent;
    }
    &::-webkit-slider-runnable-track {
      background: linear-gradient(
        to right,
        $primary-color 0%,
        $primary-color var(--videoProgress),
        rgba(0, 0, 0, 0) var(--videoProgress),
        rgba(0, 0, 0, 0) 100%
      );
    }
  }

  &::-webkit-slider-runnable-track {
    width: 100%;
    height: 5px;
    cursor: pointer;
    animate: 0.2s;
    background: linear-gradient(
      to right,
      $primary-color 0%,
      $primary-color var(--videoProgress),
      rgba(0, 0, 0, 0) var(--videoProgress),
      rgba(0, 0, 0, 0) 100%
    );
  }
  &::-webkit-slider-thumb {
    // height: 3px;
    // width: 3px;
    background: $white;
    cursor: pointer;
    appearance: none;
    margin-top: 0px;
    -webkit-appearance: none;
  }
  &::-moz-range-track {
    width: 100%;
    height: 5px;
    cursor: pointer;
    animate: 0.2s;
    background: linear-gradient(
      to right,
      $primary-color 0%,
      $primary-color var(--videoProgress),
      rgba(0, 0, 0, 0) var(--videoProgress),
      rgba(0, 0, 0, 0) 100%
    );
  }
  &::-moz-range-thumb {
    height: 5px;
    width: 10px;
    background: $primary-color;
    cursor: pointer;
  }
  &::-ms-track {
    width: 100%;
    height: 5px;
    cursor: pointer;
    animate: 0.2s;
    background: transparent;
    border-color: transparent;
    color: transparent;
  }
  &::-ms-fill-lower {
    background: $primary-color;
  }
  &::-ms-fill-upper {
    background-color: transparent;
  }
  &::-ms-thumb {
    margin-top: 1px;
    height: 5px;
    width: 10px;
    background: $primary-color;
    cursor: pointer;
  }
}
</style>
<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faPause, faPlay } from "@fortawesome/free-solid-svg-icons";
import CustomNav from "../components/CustomNav.vue";
export default {
  components: {
    CustomNav,
    FontAwesomeIcon,
  },
  data: () => {
    return {
      currentTime: 0,
      faPause,
      faPlay,
      videoPlay: false,
    };
  },
  mounted() {
    this.$refs.player.addEventListener("timeupdate", this.updateCurrentTime);
  },
  beforeDestroy() {
    this.$refs.player.removeEventListener("timeupdate", this.updateCurrentTime);
  },
  methods: {
    updateCurrentTime(ev) {
      const v = ev.target;
      document.documentElement.style.setProperty(
        "--videoProgress",
        `${(v.currentTime / v.duration) * 100}%`
      );
    },
    seek(ev) {
      this.player.currentTime = ev.target.value;
    },
    toggleVideo() {
      if (this.videoPlay) {
        this.$refs.player.pause();
        this.videoPlay = false;
      } else {
        this.$refs.player.play();
        this.videoPlay = true;
      }
    },
    openApp(type) {
      window.open(
          type === 'ios'
              ? 'https://apps.apple.com/us/app/senderrand-app/id1605173045'
              : 'https://play.google.com/store/apps/details?id=com.senderrand.sender',
          '_blank'
      );
    },
  },
  computed: {
    player() {
      if (this.$refs.player && this.$refs.player.readyState > 0) {
        return this.$refs.player;
      }
      // Return a stub to prevent errors
      return {
        currentTime: 0,
        duration: 5,
        readyState: 0,
        paused: true,
        ended: false,
      };
    },
  },
};
</script>
